import zwitCore from "zw-core";
import axios from "axios";
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";
//忘记密码
export function repwd(data) {
  return zwitCore.HTTP.post(
    BASE_URL + "/zw-user/reset/nf/scholarRetrievePwd",
    data,
    false
  );
}
//子账号登录 中文知识网的
export function sonLogin(data) {
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-user/subUser/nf/login",
    data,
    false
  );
}
//登录注册
export function login(data) {
  return zwitCore.HTTP.json(BASE_URL + "/zw-user/bj/login", data, false);
}
export function reg(data) {
  return zwitCore.HTTP.json(BASE_URL + "/zw-user/register/user", data, false);
}

export function logOut() {
  return zwitCore.HTTP.json(BASE_URL + "/zw-user/logout");
}
// 生成
export function captchaPhone(phone) {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/captcha/", { phone },false);
}
// 校验
export async function captchaVerify(data) {
    return await axios({
        url: `${BASE_URL}/zw-public/captcha/verify`,
        method: "post",
        data,
        headers: {
            rsa: "no",
            'Content-Type': 'application/json',
        },
    });
}
